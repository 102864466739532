import React, { useEffect } from "react";
import Navbar from "src/Commons/Navbar";
import Footer from "src/Commons/Footer";
import LastMileMiddleSec from "src/LastMile/LastMileMiddleSec";
import EcommersBanner from "src/Ecommerce/EcommersBanner";
import EcommerceOptimazation from "src/Ecommerce/EcommerceOptimazation";

const Ecommerce = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <EcommersBanner />
      {/* <EcommerceOptimazation/> */}
      <div className="branches_middle_section_first_main">
        <div className="container">
          <div className="row py-5 ">
            <div className="col-md-6 col-12 d-flex align-items-center ">
              <div data-aos="zoom-in-up">
                <h2>Quality at its best</h2>
                <p>
                When renting a cargo van with Branches Global Networks, you're not just getting a vehicle – you're stepping into a world of comfort and convenience. Our cargo vans are designed with luxury, offering spacious interiors, smooth handling, and advanced features that make every trip a pleasure. Whether transporting goods or embarking on a personal project, you'll enjoy a premium experience with amenities that elevate the ordinary into something extraordinary. With us, cargo van rental means efficiency without sacrificing the luxury you deserve
                </p>
              </div>
            </div>
            <div data-aos="zoom-in-up" className="col-md-6 col-12">
              <img
                src="/tour_images/4.jfif"
                alt="section-first"
              />
            </div>
          </div>

          <div className="row py-5 ">
            <div data-aos="zoom-in-up" className="col-md-6 col-12">
              <img
                src="/tour_images/2.jfif"
                alt="section-first"
              />
            </div>
            <div className="col-md-6 col-12 d-flex align-items-center ">
              <div data-aos="zoom-in-up">
                <h2>Cleaning & Servicing</h2>
                <p>
                At Branches Global Networks, we believe that luxury begins with cleanliness. Every rental vehicle in our fleet, including our premium cargo vans, is meticulously cleaned and sanitized to ensure a spotless and fresh experience for our customers. From the pristine interiors to the polished exteriors, stepping into one of our vans feels like entering a brand-new vehicle. The attention to detail in cleanliness complements the luxury features, allowing you to enjoy both comfort and peace of mind, knowing your rental meets the highest standards of hygiene and elegance
                </p>
              </div>
            </div>
          </div>

          <div className="row py-5 ">
            <div className="col-md-6 col-12 d-flex align-items-center ">
              <div data-aos="zoom-in-up">
                <h2>Rent with confidence</h2>
                <p>
                Renting a cargo van or bus with Branches Global Networks is the perfect way to explore the city and embark on an unforgettable adventure. With our reliable, well-maintained vehicles, you can travel with peace of mind, knowing you're in good and safe hands. Whether touring landmarks, heading to a special event, or simply exploring new places, our fleet is designed to meet your comfort and safety needs. Our experienced drivers and top-tier service ensure your journey is smooth, stress-free, and exciting. Let us handle the logistics while you focus on making memories!
                </p>
              </div>
            </div>
            <div data-aos="zoom-in-up" className="col-md-6 col-12">
              <img
                src="/tour_images/3.jfif"
                alt="section-first"
              />
            </div>
          </div>

        </div>
      </div>
      <LastMileMiddleSec />
      <Footer />
    </>
  );
};

export default Ecommerce;
