import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import Home from "./components/Home";
// import Service from "./components/Service";
import Aboutus from "./components/Aboutus";
import Contactus from "./components/Contactus";
import Carrier from "./components/Carrier";
import Privacy from "./Privacy/Privacy";
import Terms from "./Privacy/Terms";
import Values from "./components/Values";
import OurDefrences from "./components/OurDefrences";
import WhiteLayout from "./ServicesInnerPages/WhiteLayout";
import LastMile from "./ServicesInnerPages/LastMile";
import DropShip from "./ServicesInnerPages/DropShip";
import Ecommerce from "./ServicesInnerPages/Ecommerce";
import Fulfillment from "./ServicesInnerPages/Fulfillment";

import BlogDetails from "./components/BlogDetails";




const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about-us",
    element: <Aboutus />,
  },
  {
    path: "/blog/:slug/:id",
    element: <BlogDetails />,
  },
  {
    path: "Tours",
    element: <Ecommerce />,
  },
  {
    path: "fulfillment",
    element: <Fulfillment />,
  },
  {
    path: "whiteglove",
    element: <WhiteLayout />,
  },
  {
    path: "lastmile",
    element: <LastMile />,
  },
  {
    path: "stake-bed",
    element: <DropShip />,
  },
  {
    path: "/contact-us",
    element: <Contactus />,
  },
  {
    path: "/carrier",
    element: <Carrier />,
  },
  {
    path: "/terms",
    element: <Terms/>,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/values",
    element: <Values />,
  },
  {
    path: "/our-difference",
    element: <OurDefrences />,
  },
]);

function Routes() {
  return <RouterProvider router={router} />;
}

export default Routes;
