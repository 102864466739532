import React,{useEffect} from "react";
import "src/Styles/Fulfillment/FulfillmentBanner.css";
import AOS from 'aos';
import 'aos/dist/aos.css';




const FulfillmentBanner = () => {
  
  useEffect(() => {
    AOS.init({
         duration: 800,
         once: false,
       })
 }, [])

  return (
    <>
      <div className="FulfillmentBanner second_fullfilment ">
        <div className="container-fluid m-0 p-0">
          <img src='/new_banners_images/fullfilbanner.png' alt="image" />
          <div  data-aos="zoom-in-up" className="FulfillmentBanner_content">
            <h1> Fulfillment And Truck Transport From One Source </h1>
            <p>
              Fulﬁllment service is vital for efﬁcient supply chain manage ment,
              ensuring timely product delivery. We customize our operations to
              meet each customer's unique needs. This includes offering ﬂexible
              transportation options, leveraging advanced technology for
              optimization, and providing value -added services like order
              fulﬁllment and inventory manag ement. Our goal is to be a trusted
              partner, delivering tailored solutions that contribute to our
              customers' success.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FulfillmentBanner;
