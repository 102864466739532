import React from 'react'
import 'src/Styles/quotessection.css'
import { useNavigate } from "react-router-dom";


function QuotesSection() {
  const navigation = useNavigate();

  return (
    <div className='branches_quotes_last_section' >
      <div className="container">
      <div className="branches_quotes_last_section_main">
   
          <div className="row">
            <div className="col-md-6 col-12 d-flex align-items-center py-md-5 p-3 ">
              <div>
                <h2>Branches Global Network</h2>
                <p>
                  At Branches Global Networks, we recognize that every fleet is
                  unique, much like a fingerprint. Each fleet has its own
                  specific goals and challenges that necessitate personalized
                  solutions. Rather than offering generic recommendations
                </p>
                <button 
                onClick={() => {
                  navigation("/about-us")
                  window.scrollTo(0, 0)
                }}
                className="branchesglobalnetworks_global_button mt-3">
                  Learn More
                </button>
              </div>
            </div>
            <div className="col-md-6 col-12 d-flex justify-content-center align-items-center">
              <img
                src="/images/home_page_last_section_image.png"
                alt="section-first"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuotesSection
