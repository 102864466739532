import React from "react";
import { NavLink } from "react-bootstrap";
import "src/Styles/Carreier/CareerOurlatest.css";
import { IoArrowForwardSharp } from "react-icons/io5";
const CareerOurlatest = () => {
  return (
    <div className="branches-career-our-latest-mani">
      <div className="container">
        <div className="branches-career-our-latest-heading">
          <h1>Our Latest Insight</h1>
        </div>
        <div className="branches-career-our-latest-section">
          <div className="row">
            <div className="col-md-4 col-12 mt-md-0 mt-4">
              <div className="branches-career-our-latest-section-card">
                <img src="/CarriersImages/latestour1.jpg" alt="latest" />
                <p style={{width:"50px"}}>Blog</p>
                <h4>Press Release: Autofleet launches self-serve Simulator</h4>
                <NavLink href='/our-difference' >
                  Learn more <IoArrowForwardSharp />{" "}
                </NavLink>
              </div>
            </div>
            <div className="col-md-4 col-12 mt-md-0 mt-4">
              <div className="branches-career-our-latest-section-card">
                <img src="/CarriersImages/latestour2.jpg" alt="latest" />
                <p style={{width:"130px"}}>Press Release</p>
                <h4>Autofleet Announces Nova – the First AI-Powered Large Language Model Specifically Designed for Fleet Management	</h4>
                <NavLink href='/our-difference'>
                  Learn more <IoArrowForwardSharp />{" "}
                </NavLink>
              </div>
            </div>
            <div className="col-md-4 col-12 mt-md-0 mt-4">
              <div className="branches-career-our-latest-section-card">
                <img src="/CarriersImages/latestour3.jpg" alt="latest" />
                <p style={{width:"110px"}}>Case Study</p>
                <h4>Customer Video Spotlight Featuring: Zipcar Carsharing</h4>
                <NavLink href='/our-difference'>
                  Learn more <IoArrowForwardSharp />{" "}
                </NavLink>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerOurlatest;
